import React from 'react';
const Contacto = () => {
    return (  
        <main className='main-section'>
            <h1>Contacto</h1>
        </main>
        );
}
 
export default Contacto;
