import React from 'react';
const Portafolio = () => {
    return (  
        <main className='main-section portfolio'>
            <h1>Portafolio</h1>
        </main>
        );
}
 
export default Portafolio;
